import React from "react"
import { StaticImage } from "gatsby-plugin-image"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import { EffectCoverflow, Pagination } from "swiper"
import { useTranslation } from "react-i18next"
import { useMediaQuery, useTheme } from "@material-ui/core"
import "./testimonySection.scss"

function TestimonySection() {
  const { t } = useTranslation()

  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"))
  return (
    <div className="testimoni">
      <div className="section-title">
        <span className="section-text">{t(`home.what-they-said`)}</span>
      </div>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        slidesPerView={isDesktop ? 3 : 1}
        slidesPerGroup={isDesktop ? 2 : 1}
        initialSlide={0}
        pagination={{ clickable: true }}
        coverflowEffect={{
          rotate: 0,
          stretch: -40,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper testimoni-swiper"
      >
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/Testi-Tamara Chandra.png"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Tamara Chandra</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(() => (
                <StaticImage
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.testimoni-1`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/Testi-Zafirah Rizka.png"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Zafirah Rizka</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(() => (
                <StaticImage
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.testimoni-2`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/Testi-Umar Pudji.png"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Umar Pudji</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(() => (
                <StaticImage
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.testimoni-3`)}</span>
          </div>
        </SwiperSlide>
        <SwiperSlide className="testimoni-swiper-slide">
          <div className="slide-container">
            <StaticImage
              src={
                "../../assets/images/picture/home/testimoni/Testi-Amanda.png"
              }
              className="testimoni-photo"
              alt="tesimoni"
            />
            <span className="name">Amanda</span>
            <div className="star-container">
              {[1, 2, 3, 4, 5].map(() => (
                <StaticImage
                  src={"../../assets/images/icons/star_full.png"}
                  className="star"
                  alt="star"
                />
              ))}
            </div>
            <span className="comment">{t(`home.testimoni-4`)}</span>
          </div>
        </SwiperSlide>
      </Swiper>
      <StaticImage
        src={"../../assets/images/decoration/testimoni-circle-1.svg"}
        className="testimoni-circle-1"
        alt=""
      />
      <StaticImage
        src={"../../assets/images/decoration/testimoni-circle-2.svg"}
        className="testimoni-circle-2"
        alt=""
      />
    </div>
  )
}

export default TestimonySection
