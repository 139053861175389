import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import Fade from "react-reveal/Fade"
import Flip from "react-reveal/Flip"
import { withTrans } from "../../i18n/withTrans"
import MaskedInput from "react-text-mask"
import { createNumberMask } from "text-mask-addons"
/** COMPONENTS */
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import useStyles from "../../styles/startHereStyles"
import "./startHere.scss"
/** MATERIAL UI */
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { Paper, Select, MenuItem, FormHelperText } from "@material-ui/core"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import { Autoplay, Pagination } from "swiper"
import AppDataContext from "../../contexts/AppDataContext"

import { Skeleton } from "@material-ui/lab"
import TestimonySection from "../../components/Lender/testimonySection"
import LenderStory from "../../components/Lender/lenderStory"
import LinkApp from "../../components/linkApp"

const CurrencyInput = ({ maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...{
      prefix: "Rp ",
      suffix: "",
      includeThousandsSeparator: true,
      thousandsSeparatorSymbol: ".",
      allowDecimal: false,
      allowNegative: false,
      allowLeadingZeroes: false,
    },
    maskOptions,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
  inputMode: "numeric",
  maskOptions: {},
}

function LenderPage() {
  const { t, i18n } = useTranslation()

  const appDataContext = useContext(AppDataContext)

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("xs"))

  const classes = useStyles(isMobile)

  const [profit, setProfit] = React.useState("")
  const [nominal, setNominal] = React.useState("")

  const calculateProfit = () => {
    if (nominal) {
      let nominalInt = parseInt(nominal.replace(/[^0-9-]+/g, ""))
      setProfit(rupiah(nominalInt + (nominalInt * 4.2) / 100))
    }
  }

  const rupiah = number => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number)
  }

  const bannerLoader = () => {
    let skeleton = []

    for (let i = 0; i < 3; i++) {
      skeleton.push(
        <SwiperSlide>
          <Skeleton
            variant="rounded"
            animation="wave"
            style={{ backgroundColor: "#fdfdfd" }}
            height={540}
          />
        </SwiperSlide>
      )
    }

    return skeleton
  }
  const bannerContents = bannerDatas => {
    let contents = []
    if (bannerDatas?.length === 0) {
      contents.push(
        <SwiperSlide>
          <a
            href="https://play.google.com/store/apps/details?id=com.silvrr.finance"
            target="_blank"
            rel="noreferrer"
          >
            {isMobileSmall ? (
              i18n.language === "en" ? (
                <StaticImage
                  src="../../assets/images/picture/home/banner/phone-en.png"
                  className="banner-image"
                  alt=""
                />
              ) : (
                <StaticImage
                  src="../../assets/images/picture/home/banner/phone-in.png"
                  className="banner-image"
                  alt=""
                />
              )
            ) : i18n.language === "en" ? (
              <StaticImage
                src="../../assets/images/picture/home/banner/desktop-en.png"
                className="banner-image"
                alt=""
              />
            ) : (
              <StaticImage
                src="../../assets/images/picture/home/banner/desktop-in.png"
                className="banner-image"
                alt=""
              />
            )}
          </a>
        </SwiperSlide>
      )
    } else {
      bannerDatas?.forEach(bannerItem => {
        contents.push(
          <SwiperSlide>
            <a
              href={
                bannerItem.url.length === 0
                  ? null
                  : bannerItem.url.includes("https://")
                  ? bannerItem.url
                  : `//` + bannerItem.url
              }
              target={bannerItem.url.length === 0 ? null : "_blank"}
              rel="noreferrer"
            >
              {isMobileSmall ? (
                i18n.language === "en" ? (
                  <img
                    src={bannerItem.bannerEnMobileURL}
                    className="banner-image"
                    alt=""
                  />
                ) : (
                  <img
                    src={bannerItem.bannerIdMobileURL}
                    className="banner-image"
                    alt=""
                  />
                )
              ) : i18n.language === "en" ? (
                <img
                  src={bannerItem.bannerEnDesktopURL}
                  className="banner-image"
                  alt=""
                />
              ) : (
                <img
                  className="banner-image"
                  alt=""
                  src={bannerItem.bannerIdDesktopURL}
                />
              )}
            </a>
          </SwiperSlide>
        )
      })
    }

    return contents
  }

  return (
    <Layout>
      <Seo id={"start-here"} title={t(`menus.lender`)} />
      {/* SECTION BANNER TOP */}
      {/* <Container style={{paddingLeft: '0px', paddingRight: '0px'}}> */}
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        modules={[Autoplay, Pagination]}
        className="mySwiper banner"
      >
        {appDataContext.bannerData === undefined
          ? bannerLoader()
          : bannerContents(appDataContext.bannerData)}
      </Swiper>
      {/* </Container> */}

      {/* SECTION WHY CHOOSE ASETKU */}
      <Container>
        <div className={classes.whyChoose}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <StaticImage
              src={"../../assets/images/decoration/why-choose-circle.svg"}
              className={classes.whyChooseCircle}
              alt=""
            />
            {/* SECTION 1 */}
            <Grid item md={12} xs={12} className={classes.whyChooseTitle}>
              {t(`start-here.why-title`)}
            </Grid>

            {/* SECTION 2 */}
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={1000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u21_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-1-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-1-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={1000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u16_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-2-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-2-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={2500}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u9_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-3-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-3-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={2500}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u12_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-4-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-4-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={4000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u28_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-5-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-5-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <div className={classes.whyChooseContentImage}>
                <Flip bottom duration={4000}>
                  <StaticImage
                    src={"../../assets/images/icons/normal_u23_div.png"}
                    className={classes.whyChooseContentIcon}
                    alt=""
                  />
                  <div className={classes.whyChooseContentTextBox}>
                    <Typography className={classes.whyChooseContentTitleText}>
                      {t(`start-here.why-6-title`)}
                    </Typography>
                    <Typography
                      className={classes.whyChooseContentSubTitleText}
                    >
                      {t(`start-here.why-6-subtitle`)}
                    </Typography>
                  </div>
                </Flip>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>

      {/* SECTION YELLOW
      {isMobile ? (
        <>
          <div className={classes.black}>
            <Container>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item lg={6} md={12} style={{ paddingTop: 20 }}>
                  <div className={classes.yellowRightSideMobile}>
                    <Slide left>
                      <div className={classes.yellowLeftSideTItle}>
                        {t(`start-here.yellow-left-title`)}
                      </div>
                    </Slide>
                    <Slide delay={500}>
                      <div className={classes.yellowLeftSideContent}>
                        {t(`start-here.yellow-left-content`)}
                      </div>
                    </Slide>
                  </div>
                </Grid>
              </Grid>
              <div className={classes.whiteUnderline}></div>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item lg={6} md={12} style={{ paddingTop: 20 }}>
                  <div
                    className={
                      isMobile
                        ? classes.yellowRightSideMobile
                        : classes.yellowRightSideDesktop
                    }
                  >
                    <Slide right>
                      <div className={classes.yellowRightSideTItle}>
                        {t(`start-here.yellow-right-title`)}
                      </div>
                    </Slide>
                    <Slide right delay={500}>
                      <div className={classes.yellowRightSideContent}>
                        {t(`start-here.yellow-right-content`)}
                      </div>
                    </Slide>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      ) : (
        <div className={classes.black}>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid
                item
                lg={6}
                md={12}
                style={isMobile ? { paddingBottom: 40 } : {}}
              >
                <div
                  className={
                    isMobile
                      ? classes.yellowLeftSideMobile
                      : classes.yellowLeftSideDesktop
                  }
                >
                  <Slide left>
                    <div
                      className={classes.yellowLeftSideTItle}
                      style={{ textAlign: "left" }}
                    >
                      {t(`start-here.yellow-left-title`)}
                    </div>
                  </Slide>
                  <Slide left delay={500}>
                    <div
                      className={classes.yellowLeftSideContent}
                      style={{ textAlign: "left" }}
                    >
                      {t(`start-here.yellow-left-content`)}
                    </div>
                  </Slide>
                </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                style={isMobile ? { paddingTop: 40 } : {}}
              >
                <div
                  className={
                    isMobile
                      ? classes.yellowRightSideMobile
                      : classes.yellowRightSideDesktop
                  }
                >
                  <Slide right>
                    <div
                      className={classes.yellowRightSideTItle}
                      style={{ textAlign: "right" }}
                    >
                      {t(`start-here.yellow-right-title`)}
                    </div>
                  </Slide>
                  <Slide right delay={500}>
                    <div
                      className={classes.yellowRightSideContent}
                      style={{ textAlign: "right" }}
                    >
                      {t(`start-here.yellow-right-content`)}
                    </div>
                  </Slide>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      )} */}

      {/* SECTION LINK APPS */}
      {/* <div className={classes.linkApps}>
        <Container>
          <div className={classes.linkAppsCenter}>
            <a
              href="https://play.google.com/store/apps/details?id=com.silvrr.finance"
              target="_blank"
              className={classes.linkAppsButton}
              rel="noreferrer"
            >
              <div className={classes.linkAppsButtonImg}>
                <StaticImage
                  src={"../../assets/images/icons/normal_u87_div.png"}
                  width={28}
                  alt=""
                />
              </div>
              <div className={classes.linkAppsButtonDesc}>
                {t(`general.download-on`)} <br />
                <b>
                  <font style={{ fontSize: 14 }}>GOOGLE PLAY</font>
                </b>
              </div>
            </a>

            <a
              href="https://apps.apple.com/id/app/asetku/id1300452602"
              target="_blank"
              className={classes.linkAppsButton}
              rel="noreferrer"
            >
              <div className={classes.linkAppsButtonImg}>
                <StaticImage
                  src={"../../assets/images/icons/normal_u92_div.png"}
                  width={28}
                  alt=""
                />
              </div>
              <div className={classes.linkAppsButtonDesc}>
                {t(`general.download-on`)} <br />
                <b>
                  <font style={{ fontSize: 14 }}>APP STORE</font>
                </b>
              </div>
            </a>
          </div>
        </Container>
      </div> */}

      {/* Fund Now*/}
      <div className="apply-for-funding" id="download">
        <div className="rounded-top">
          <Container className="container">
            <div className="section-container">
              <div className="section-title">
                <Flip top duration={1500}>
                  {isMobileSmall ? (
                    i18n.language === "en" ? (
                      <StaticImage
                        src="../../assets/images/picture/home/fund-now/title-phone-en.png"
                        className="title"
                        alt=""
                      />
                    ) : (
                      <StaticImage
                        src="../../assets/images/picture/home/fund-now/title-phone-id.png"
                        className="title"
                        alt=""
                      />
                    )
                  ) : i18n.language === "en" ? (
                    <StaticImage
                      src="../../assets/images/picture/home/fund-now/title-desktop-en.png"
                      className="title"
                      alt=""
                    />
                  ) : (
                    <StaticImage
                      src="../../assets/images/picture/home/fund-now/title-desktop-id.png"
                      className="title"
                      alt=""
                    />
                  )}
                </Flip>
                <div className="section-description">
                  <Flip top duration={1500}>
                    {isMobileSmall ? (
                      i18n.language === "en" ? (
                        <StaticImage
                          src="../../assets/images/picture/home/fund-now/desc-phone-en.png"
                          className="description"
                          alt=""
                        />
                      ) : (
                        <StaticImage
                          src="../../assets/images/picture/home/fund-now/desc-phone-id.png"
                          className="description"
                          alt=""
                        />
                      )
                    ) : i18n.language === "en" ? (
                      <StaticImage
                        src="../../assets/images/picture/home/fund-now/desc-desktop-en.png"
                        className="description"
                        alt=""
                      />
                    ) : (
                      <StaticImage
                        src="../../assets/images/picture/home/fund-now/desc-desktop-id.png"
                        className="description"
                        alt=""
                      />
                    )}
                  </Flip>
                </div>
                {!isMobileSmall && (
                  <Flip top duration={1500}>
                    <LinkApp />
                  </Flip>
                )}
              </div>
              <div className="fund-now-image-container">
                <StaticImage
                  src="../../assets/images/picture/home/fund-now/fund-now.png"
                  className="fund-now-1"
                  alt="fund now"
                />

                <StaticImage
                  src="../../assets/images/picture/home/fund-now/fund-now-2.png"
                  className="fund-now-2"
                  alt="fund now"
                />

                <StaticImage
                  src="../../assets/images/picture/home/fund-now/fund-now-3.png"
                  className="fund-now-3"
                  alt="fund now"
                />
              </div>
              {isMobileSmall && (
                <Flip top duration={1500}>
                  <LinkApp />
                </Flip>
              )}
            </div>
          </Container>
          <StaticImage
            src="../../assets/images/decoration/fund-now-circle.svg"
            className="fund-now-circle"
            alt="cirle"
          />
        </div>
      </div>

      {/* SECTION TOTAL COST */}
      <div className={classes.calculatorContainer}>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item xs={12} md={6} lg={6}>
              <Flip bottom>
                <h3 className={classes.fundingCalculation}>
                  {t(`start-here.funding-calculation`)}
                </h3>
              </Flip>

              {/* INPUT NOMINAL */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-input-nominal`)}
                </Grid>
                <Grid className={classes.calculateInputBox}>
                  <Paper component="form" className={classes.paperInputNominal}>
                    <CurrencyInput
                      className={classes.inputNominal}
                      placeholder={t(`start-here.calculator-input-placeholder`)}
                      type="text"
                      onChange={event => setNominal(event.target.value)}
                    />
                  </Paper>
                </Grid>
              </Grid>

              {/* CHOOSE PRODUCT */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-choose-product`)}
                </Grid>
                <Grid className={classes.calculateInputBox}>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without Label" }}
                    className={classes.chooseProduct}
                  >
                    <MenuItem>Pendanaan Super 30 Hari</MenuItem>
                  </Select>
                  <FormHelperText className={classes.chooseProductHelperText}>
                    {t("start-here.choose-product-helper")}
                  </FormHelperText>
                </Grid>
              </Grid>

              {/* TOTAL PROFIT */}
              <Grid item md={12} xs={12}>
                <Grid className={classes.calculateTextLabel}>
                  {t(`start-here.calculate-total-profit-label`)}
                </Grid>
                <Grid className={classes.totalProfit}>{profit}</Grid>
              </Grid>

              <Grid item md={12} xs={12}>
                <button
                  className={classes.calculateButton}
                  onClick={calculateProfit}
                >
                  {t(`start-here.calculate-button`)}
                </button>
              </Grid>

              <StaticImage
                src={"../../assets/images/picture/normal_calculator_u42.png"}
                className={classes.imageCalclulator}
                alt=""
              />
            </Grid>
          </Grid>
        </Container>
      </div>

      <div className={classes.creditSectionWrapper}>
        {isDesktop && (
          <StaticImage
            src="../../assets/images/icons/normal_u68.png"
            width={68}
            alt=""
          />
        )}
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={5}
          >
            <Grid item lg={6} md={5} xs={12}>
              <Flip bottom>
                <h3 className={classes.creditTitle}>
                  {t("start-here.credit-title-1")}
                </h3>
              </Flip>
              <div className={classes.creditImage}>
                <Fade>
                  {i18n.language === "en" ? (
                    <StaticImage
                      src="../../assets/images/picture/Flow_Credit_Insurance_English.jpg"
                      alt=""
                    />
                  ) : (
                    <StaticImage
                      src="../../assets/images/picture/Flow_Credit_Insurance_Indo.jpg"
                      alt=""
                    />
                  )}
                </Fade>
                <Flip bottom>
                  <p className={classes.creditText}>
                    {t("start-here.credit-text-1")}
                  </p>
                </Flip>
              </div>
            </Grid>
            {!isDesktop && (
              <StaticImage
                src="../../assets/images/icons/normal_u68.png"
                width={68}
                alt=""
              />
            )}
            <Grid item lg={6} md={5} xs={12}>
              <Flip bottom delay={500}>
                <h3 className={classes.creditTitle}>
                  {t("start-here.credit-title-2")}
                </h3>
              </Flip>
              <div className={classes.creditImage}>
                <Fade delay={500}>
                  {i18n.language === "en" ? (
                    <StaticImage
                      src="../../assets/images/picture/Chart_English.png"
                      alt=""
                    />
                  ) : (
                    <StaticImage
                      src="../../assets/images/picture/Chart_Indo.png"
                      alt=""
                    />
                  )}
                </Fade>
              </div>
              <Flip bottom delay={500}>
                <p className={classes.creditText}>
                  {t("start-here.credit-text-2")}
                </p>
              </Flip>
            </Grid>
          </Grid>
        </Container>
      </div>
      <TestimonySection />
      <LenderStory />
    </Layout>
  )
}

export default withTrans(LenderPage)
